import React from 'react'
import { useState, useEffect } from 'react';
import Switch from '@mui/material/Switch';
import { ThemeProvider } from '@emotion/react';

import { alpha, styled } from '@mui/material/styles';
import { createTheme } from '@mui/material';

export default function Navbar(props) {
  const {setDark, dark} = props;
  const getSticky = () => {
    // if (window.innerWidth <= 1024){
    //   return true
    // }
    return true
  }
  const [isSticky, setIsSticky] = useState(false);
  const [navbarFixed, setNavbarFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0){
        setIsSticky(true)
      }else{
        setIsSticky(false)
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const myStyles = {
    color: dark ? '#ffffff' : isSticky ? '#ffffff' : '#000000'
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setNavbarFixed(true);
    } else {
      setNavbarFixed(false);
    }
  };


  const navStyle = {
    position : isSticky ? 'fixed' : 'relative',
    backgroundColor: isSticky ? 'black' : null,
    color: dark ? '#ffffff' : '#000000'
  }

 

  return (
    
    <nav style={navStyle} className="navbar navbar-expand-lg navbar-dark ftco_navbar ftco-navbar-light site-navbar-target" id="ftco-navbar">
            <div className="container">
            <a className="navbar-brand" style={myStyles} href="#intro">Viswanath</a>
            <button className="navbar-toggler js-fh5co-nav-toggle fh5co-nav-toggle" type="button" data-toggle="collapse" data-target="#ftco-nav" aria-controls="ftco-nav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="oi oi-menu"></span> Menu
            </button>

            <div className="collapse navbar-collapse" id="ftco-nav">
                <ul style={{margin:0}}  className="navbar-nav nav ml-auto">
                <li className="nav-item"><a href="#about-section" style={myStyles} className="nav-link"><span>About</span></a></li>
                <li className="nav-item"><a href="#resume-section" style={myStyles} className="nav-link"><span>Work experience</span></a></li>
                <li className="nav-item"><a href="#skills-section" style={myStyles} className="nav-link"><span>Skills</span></a></li>
                <li className="nav-item"><a href="#projects-section" style={myStyles} className="nav-link"><span>Projects</span></a></li>
                <li className="nav-item"><a href="#certificates-section" style={myStyles} className="nav-link"><span>Certificates</span></a></li>
                <li className="nav-item"><a href="#accomplishments-section" style={myStyles} className="nav-link"><span>Accomplishments</span></a></li>
                <li className="nav-item"><a href="#contact-section" style={myStyles} className="nav-link"><span>Contact</span></a></li>
                </ul>
            </div>

            <div style={{marginLeft:'auto'}}>
                <Switch color={dark ? 'primary' : 'default'}  onChange={(e) => {
                  console.log(e.target.checked)
                  setDark(e.target.checked)
                }} defaultChecked />
              
            </div>
            </div>
    </nav>
    
  )
}




