import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import ProjectCard from '../components/ProjectCard';
import Grid from '@mui/material/Grid';
import TextCard from '../components/TextCard';


export default function Accomplishments(props) {
  const {dark} = props;

  const myStyles = {
    color: dark ? '#ffffff' : '#000000'
  }

  const bgtextStyle = {
		color: dark ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
		zIndex: 1,
		position: 'absolute',
		top: -20
	};

  const accomplishes = [{
    "title": " Five-star coder in Hackerrank, 2019 - Hackerrank Profile",
    "id": "AccCode",
    "data": [{
      "text": 'Five star coder in Hackkerank platform:  Enthusiastic and results-driven software developer with proficiency in Java, Python, C, and JavaScript. Recognized as a five-star coder on HackerRank, showcasing strong problem-solving abilities and dedication to mastering diverse programming languages.',
      "link": "https://www.hackerrank.com/viswanath30796",
      "image": "https://storage.googleapis.com/portfolio-399909.appspot.com/cryptoAnalysis/hackerrank.png"
    }]
  },{
    "title": "Stood second among 50 teams in Smart India Hackathon (SIH) at K L University in February 2020",
    "id": "AccSIH",
  },{
    "title":"Started Mayavi tech club a technology club on Augmented Reality, Virtual Reality and Gaming in K L University",
    "id": "AccClub",
    "data": [{
      "text": "I started Mayavi Tech Club to bring tech-loving students together and create a space for learning and creativity. Over time, the club has grown to include about 450 active members. We organize weekly sessions and collaborate with industry giant Unity for valuable tech insights. Our club is also known for hosting exciting hackathons and events.",
      "link":"https://www.linkedin.com/company/mayavi-technology-club/",
      "image": "https://storage.googleapis.com/portfolio-399909.appspot.com/Mayavi/mayaviSession.jpg"
    }]
  }
]

  return (
    <section className="ftco-about img ftco-section ftco-no-pb" id="accomplishments-section">
    <div className="container">
      <hr/>
        <div class="row justify-content-center pb-5">
            <div className="col-md-12 heading-section text-center">
                <h1 className="big big-2" style={bgtextStyle}>Accomplishments</h1>
                <h2 className="mb-4" style={myStyles}>Accomplishments</h2>
            </div>
        </div>
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        {
          accomplishes?.map((accomplish) => {
            return (
              <Grid item xs={12} sm={12}>
                <TextCard data={accomplish} dark={dark}/>
              </Grid>
            )
          })
        }
      </Grid>
    </Box>
    </div>
    </section>
  );
}
