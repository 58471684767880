import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faAddressCard } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { motion, Variants } from "framer-motion";
import { faHackerrank } from '@fortawesome/free-brands-svg-icons';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { faGitlab } from '@fortawesome/free-brands-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import Reveal from '../components/Reveal';
import { Grid } from '@mui/material';

export default function ContactMe(props) {
    const {dark} = props;
    const myStyles = {
        color: dark ? '#ffffff' : '#000000'
    }
    const bgtextStyle = {
		color: dark ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
		zIndex: 1,
		position: 'absolute',
		top: -20
	};
    
  return (
    <section class="ftco-section contact-section ftco-no-pb" id="contact-section">
      <div style={{marginBottom: '10rem'}} class="container">
        <hr/>
            <div class="row justify-content-center mb-5 pb-3">
            <div class="col-md-7 heading-section text-center">
                <h1 class="big big-2" style={bgtextStyle}>Contact</h1>
                <h2 class="mb-4" style={myStyles}>Contact Me</h2>
            </div>
            </div>
            <Reveal>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={3}>
                        <div>
                            <div class="align-self-stretch box p-4 text-center">
                                <div style={{backgroundColor:'rgba(255, 255, 255, 0.3)'}} class="icon d-flex align-items-center justify-content-center">
                                    <span class="fa fa-address-card">
                                        <FontAwesomeIcon icon={faAddressCard} />
                                    </span>
                                </div>
                                <h3 class="mb-4" style={myStyles}>Address</h3>
                                <p style={myStyles}>56 Englewood Avenue, Buffalo, New York</p>
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                    <div>
                        <div class="align-self-stretch box p-4 text-center">
                            <div style={{backgroundColor:'rgba(255, 255, 255, 0.3)'}} className="icon d-flex align-items-center justify-content-center">
                                <span>
                                    <FontAwesomeIcon icon={faPhone} />
                                </span>
                            </div>
                            <h3 class="mb-4" style={myStyles}>Contact Number</h3>
                            <p><a style={myStyles} href="tel://1234567920">+1 7166178053</a></p>
                        </div>
                    </div>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                    <div>
                        <div class="align-self-stretch box p-4 text-center">
                            <div style={{backgroundColor:'rgba(255, 255, 255, 0.3)'}} class="icon d-flex align-items-center justify-content-center">
                                <span class="fa fa-envelope">
                                    <FontAwesomeIcon icon={faEnvelope} />
                                </span>
                            </div>
                            <h3 class="mb-4" style={myStyles}>Email Address</h3>
                            <p><a style={myStyles} href="mailto:mlnsviswanath@gmail.com">mlnsviswanath@gmail.com</a></p>
                        </div>
                    </div>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                    <div>
                        <div class="align-self-stretch box p-4 text-center">
                            <div style={{backgroundColor:'rgba(255, 255, 255, 0.3)'}} class="icon d-flex align-items-center justify-content-center">
                                <span class="fa fa-globe">
                                    <FontAwesomeIcon icon={faGlobe} />
                                </span>
                            </div>
                            <h3 class="mb-4" style={myStyles}>Website</h3>
                            <p><a href="https://www.viswanath.works" style={myStyles}>www.viswanath.works</a></p>
                        </div>
                    </div>
                    </Grid>
                </Grid>
            </Reveal>
      </div>

    <footer>
        <Grid container>
        <Grid item xs={3} sm={3}>
            <div>
                <div className="box">
                    <div style={{backgroundColor:'rgba(255, 255, 255, 0.3)',width: '50px', height:'50px'}} className="icon d-flex align-items-center justify-content-center">
                        <span>
                        <a target='_blank' href="https://www.linkedin.com/in/viswanath-matukumalli"><FontAwesomeIcon  style={{width:'20px'}} icon={faLinkedin} /></a>
                        </span>
                    </div>
                </div>
            </div>
        </Grid>

        <Grid item xs={3} sm={3}>
            <div>
                <div className="box">
                    <div style={{backgroundColor:'rgba(255, 255, 255, 0.3)',width: '50px', height:'50px'}} className="icon d-flex align-items-center justify-content-center">
                        <span>
                            <a target='_blank' href="#"><FontAwesomeIcon  style={{width:'20px'}} icon={faGitlab} /></a>
                        </span>
                    </div>
                </div>
            </div>
        </Grid>

        <Grid item xs={3} sm={3}>
            <div>
                <div className="box">
                    <div style={{backgroundColor:'rgba(255, 255, 255, 0.3)',width: '50px', height:'50px'}} className="icon d-flex align-items-center justify-content-center">
                        <span>
                            <a target='_blank' href="https://github.com/vissunani"><FontAwesomeIcon  style={{width:'20px'}} icon={faGithub} /></a>
                        </span>
                    </div>
                </div>
            </div>
        </Grid>

        <Grid item xs={3} sm={3}>
        <div>
            <div className="box">
                <div style={{backgroundColor:'rgba(255, 255, 255, 0.3)',width: '50px', height:'50px'}} className="icon d-flex align-items-center justify-content-center">
                    <span>
                        <a target='_blank' href="https://www.hackerrank.com/viswanath30796?hr_r=1"><FontAwesomeIcon  style={{width:'20px'}} icon={faHackerrank} /></a>
                    </span>
                </div>
            </div>
        </div>
        </Grid>
        
    </Grid>
    </footer>
    </section>
  )
}
