import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import ProjectCard from '../components/ProjectCard';
import Grid from '@mui/material/Grid';


export default function Certificates(props) {
  const {dark} = props;

  const myStyle = {
    color: dark ? '#ffffff' : '#000000'
  }
  const bgtextStyle = {
		color: dark ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
		zIndex: 1,
		position: 'absolute',
		top: -20
	};

  const certificates = [{
    "title": "Cloud Engineer,” Google Cloud Associate",
    "id": "certGCP",
    "thumbnail":"https://storage.googleapis.com/portfolio-399909.appspot.com/certificates/GCP.png",
    "features": [{
      "text": "Obtained the 'Cloud Engineer, Google Cloud Associate' certification in August 2021, showcasing expertise in Google Cloud platform technologies and practices.",
      "image": "https://storage.googleapis.com/portfolio-399909.appspot.com/certificates/GCP.png"
    }]
  },{
    "title": '"AWS Architect,” Simplilearn',
    "id": "certAWS",
    "description": "",
    "thumbnail":"https://storage.googleapis.com/portfolio-399909.appspot.com/certificates/AWS.png",
    "features": [{
      "text": "Achieved the 'AWS Architect' certification from Simplilearn in December 2019, demonstrating proficiency in architecting solutions on Amazon Web Services.",
      "image": "https://storage.googleapis.com/portfolio-399909.appspot.com/certificates/AWS.png"
    }]
  },{
    "title":"SAP Certified Citizen Developer Associate",
    "id":  "certSAP",
    "thumbnail":"https://storage.googleapis.com/portfolio-399909.appspot.com/certificates/SAP_LCNC.png",
    "features":[{
      "text":"Attained certification in SAP Low Code/No Code development, showcasing proficiency in leveraging innovative, user-friendly tools to create efficient applications and workflows within the SAP ecosystem.",
      "image": "https://storage.googleapis.com/portfolio-399909.appspot.com/certificates/SAP_LCNC.png"
    }]
  },{
    "title": '“Pre Education Program (PEP-2020),” EPAM Systems India Pvt. Ltd',
    "id": "certEPAM",
    "description": "",
    "thumbnail": "https://storage.googleapis.com/portfolio-399909.appspot.com/certificates/EPAM.png",
    "features": [{
      "text": "Participated and completed the 12-week Pre Education Program (PEP-2020) at EPAM Systems India Pvt. Ltd. in October 2020, gaining valuable insights and hands-on experience in the industry.",
      "image": "https://storage.googleapis.com/portfolio-399909.appspot.com/certificates/EPAM.png"
    }]
  },{
    "title": '“Java J2EE,” Wipro Talent Next',
    "id": "certJAVA",
    "description": "",
    "thumbnail":"https://storage.googleapis.com/portfolio-399909.appspot.com/certificates/WIPRO.png",
    "features": [{
      "text": "Successfully completed the 'Java J2EE' certification through Wipro Talent Next in October 2020, enhancing proficiency in Java and J2EE technologies",
      "image": "https://storage.googleapis.com/portfolio-399909.appspot.com/certificates/WIPRO.png"
    }]
  },{
    "title": '"Trained Unity Game Development,” Vishwakarma University',
    "id": "certUnity",
    "description": "",
    "thumbnail":"https://storage.googleapis.com/portfolio-399909.appspot.com/certificates/UNITY.png",
    "features": [{
      "text": "Completed a 7-week 'Trained Unity Game Development' program at Vishwakarma University in May 2020, gaining practical skills in Unity game development.",
      "image": "https://storage.googleapis.com/portfolio-399909.appspot.com/certificates/UNITY.png"
    }]
  },{
    "title": '"Advanced RPA Professional,” Automation Anywhere Certified',
    "id": "certRPA",
    "description": "",
    "thumbnail":"https://storage.googleapis.com/portfolio-399909.appspot.com/certificates/RPA.png",
    "features": [{
      "text": "Earned the 'Advanced RPA Professional' certification from Automation Anywhere in July 2020, demonstrating expertise in Robotic Process Automation (RPA).",
      "image": "https://storage.googleapis.com/portfolio-399909.appspot.com/certificates/RPA.png"
    }]
  }]

  return (
    <section className="ftco-about img ftco-section ftco-no-pb" id="certificates-section">
      <div className="container">
        <hr/>
          <div class="row justify-content-center pb-5">
              <div className="col-md-12 heading-section text-center">
                  <h1 className="big big-2" style={bgtextStyle}>Certificates</h1>
                  <h2 className="mb-4" style={myStyle}>My Certificates</h2>
              </div>
          </div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          {
            certificates.map((certificate) => {
              return (
                <Grid item xs={12} sm={6}>
                  <ProjectCard project={certificate} dark={dark}/>
                </Grid>
              )
            })
          }
        </Grid>
      </Box>
      </div>
    </section>
  );
}
