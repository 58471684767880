import Navbar from './Pages/Navbar';
import Intro from './Pages/Intro';
import Aboutme from './Pages/Aboutme';
import './App.css';
import Workexperience from './Pages/Workexperience';
import Projects from './Pages/Projects';
import ProjectDisplay from './Pages/ProjectDisplay';
import Certificates from './Pages/Cartificates';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ExtraActivities from './Pages/ExtraActivities';
import Accomplishments from './Pages/Accomplishments';
import ContactMe from './Pages/ContactMe';
import Reveal from './components/Reveal';
import { useState } from 'react';
import Skills from './Pages/Skills';
import Grid from '@mui/material/Grid';

function App() {
  const [dark,setDark] = useState(true);
  const darkTheme = createTheme
  ({
    typography: {
      fontFamily: '"Poppins", Arial, sans-serif',
      color: 'white'
    },
    palette: {
      mode: dark == true ? 'dark' : 'light',
    },
  });
  
  return (
    <ThemeProvider theme = {darkTheme}>
      <Grid container className={dark == true ? 'dark' : 'light'}>
        {/* <Navbar dark={dark} setDark={setDark}/> */}
        {/* <Intro dark={dark}/> */}
        {/* <Grid item xs={12}>
          <Navbar dark={dark} setDark={setDark}/>
        </Grid> */}
        <Grid xs={12}>
          <Navbar dark={dark} setDark={setDark}/>
        </Grid>

        <Grid xs={12}>
         <Intro dark={dark}/>
        </Grid> 
       
        <Grid item xs={12}>
          <Aboutme dark={dark}/>
        </Grid>
        <Grid item xs={12}>
          <Workexperience dark={dark}/>
        </Grid>
        <Grid item xs={12}>
          <ProjectDisplay dark={dark}/>
        </Grid>
        <Grid item xs={12}>
          <Skills dark={dark}/>
        </Grid>
        <Grid item xs={12}>
          <Certificates dark={dark}/>
        </Grid>
        <Grid item xs={12}>
          <Accomplishments dark={dark}/>
        </Grid>
        <Grid item xs={12}>
          <ContactMe dark={dark}/>
        </Grid>        {/* <Grid item xs={12}>
          <ContactMe dark={dark}/> 
        </Grid> */}
        {/* <Workexperience dark={dark}/> */}
        {/* <Projects/> */}
        {/* <ProjectDisplay dark={dark}/>
        <Skills dark={dark}/>
        <Certificates dark={dark}/>
        <ExtraActivities dark={dark}/>
        <Accomplishments dark={dark}/>
        <ContactMe dark={dark}/> */}
       
      </Grid>
    </ThemeProvider>
  );
}

export default App;
