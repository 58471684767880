import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import DetailsDialog from './DetailsDialog';
import { useState } from 'react';
import vissu from '../images/vissu.jpg'
import Reveal from './Reveal';
import RevealOpposite from './RevealOpposite';

export default function ProjectCard(props) {
    const [open,setOpen] = useState(false);
    const {direction,dark, project} = props;

    const colorStyle = {
        backgroundColor: dark ? 'rgba(255, 255, 255, 0.1)' : 'rgba(255, 255, 255, 0.3)', 
        border: dark ? '' : '1px solid #ffbd39',
        color: 'white'
    }

    const myStyle = {
        color: dark ? '#ffffff' : '#000000'
    }
      
   return (
    <Reveal>
    <DetailsDialog title={project?.title} data={project?.features} dark={dark} open={open} setOpen={setOpen}/>
    <Card id={project?.id} sx={colorStyle} onClick= {() => {
        setOpen(true)
    }}>
        <CardActionArea sx={{border: 'none'}}>
            <CardMedia
            component="img"
            height="200"
            padding= "10"
            src={project?.thumbnail}
            />
            <CardContent>
            <Typography style={myStyle} gutterBottom variant="h5" component="div">
                {project?.title}
            </Typography>
            <Typography style={myStyle} variant="body2">
                {project?.description}
            </Typography>
            </CardContent>
        </CardActionArea>
        <CardActions>
            <a style={{marginTop:'1.5rem'}} className="btn btn-primary py-3 px-4">Know more</a>
        </CardActions>
    </Card>
    </Reveal>
   )
   
}
