import React from 'react'
import DetailsDialog from '../components/DetailsDialog';
import { useState, useRef } from 'react';
import { animate, motion, Variants, useInView, useAnimate } from "framer-motion";
import Reveal from '../components/Reveal';
import DetailsTextDialog from '../components/DetailsTextDialog';

export default function Workexperience(props) {

	const ref = useRef(null);
	const researchAide = {
		"company":"University at Buffalo ",
		"role": "Research Aide",
		"thumbnail": "",
		"roles": {
			"UI Development and Enhancement": ["Designed and developed the application's frontend using React, focusing on creating an intuitive and engaging user interface.", "Implemented enhancements to the UI, ensuring a seamless user experience and aligning it with the project's evolving requirements."],
			"Backend Development and API Integration": ["Led the development of the backend infrastructure in Node.js, facilitating smooth communication between the frontend and server components.", "Integrated numerous APIs into the backend, optimizing system functionality and ensuring data retrieval and processing were efficient."]
		},
		"Key Achievements":["Successfully aligned the existing Android app's UI with the updated backend, fostering a cohesive and synchronized user experience.","Hosted and maintained the updated backend and frontend on Ubuntu servers, incorporating DevOps methodologies for efficient deployment, monitoring, and scalability.","Diligently addressed bugs and introduced new features based on New York State Department of Transportation (NYSDOT) agency specifications, contributing to a robust and tailored Integrated Incident Management System (IIMS)."]
	}

	const softwareEngineer = {
		"company":"Software Engineer at Agile Solutions, Bangalore (Sept 2021 – July 2022)",
		"role": "Research Aide",
		"roles": {
			"Tax Estimating Component Development": ["Led the development efforts to create a new tax estimating component within the Tax Intelligence and Management Platform (TIMP).", "Utilized JS, SAP UI5, and SAP XSJS to design and implement the tax estimating feature, enhancing the platform's capabilities."],
			"DevOps Roadmap and Automation": ["Designed and implemented a comprehensive DevOps roadmap, establishing separate repositories for different code environments to streamline development processes.","Set up a Jenkins server and devised automation strategies, ensuring efficient code conversion between various environments.","Implemented automated pipelines that facilitated seamless code builds and deployments, while promptly alerting developers about any errors in the code."],
			"Client Ticket Handling and Software Enhancements": ["Led the development of the backend infrastructure in Node.js, facilitating smooth communication between the frontend and server components.", "Integrated numerous APIs into the backend, optimizing system functionality and ensuring data retrieval and processing were efficient."]
		},
		"Key Achievements":["Commenced as an intern and demonstrated exemplary performance, resulting in a full-time role offer.","Showcased dedication, technical acumen, and adaptability in transitioning from an intern to a Software Engineer, contributing effectively to the team and projects at Agile Solutions."]
	}

	const contractEngineer = {
		"company":"Contract Software Engineer at Sinch (Oct 2021 – April 2022)",
		"role": "Research Aide",
		"roles": {
			"Frontend Development for SaaS Chatbot Platform": ["Played a key role in the development of a SaaS application focused on creating responsive chatbots.","Specialized in frontend development using React, contributing to a micro frontend platform where users could create and visualize chatbots effectively."],
			"Micro Frontend Platform and React Module": ["Worked extensively on a micro frontend architecture, focusing on a specific module built with React.","Utilized React to enhance the module's functionality and user experience, making it more intuitive and user-friendly."],
			"Technological Expertise": ["Primarily engaged in frontend development using React, crafting a seamless user interface for chatbot creation and visualization.","Additionally, gained exposure to Node.js, contributing to the project's backend functionalities."]
		},
		"Key Achievements":["Transitioned to Sinch from Agile Solutions as a contract software engineer, showcasing versatility and adaptability in varied work environments.","Demonstrated proficiency in React and frontend development, highlighting the ability to contribute effectively to a critical module within a complex SaaS application."]
	}

	const intern = {
		"company": "Intern at Agile Solutions (Sept 2020 – Sept 2021)",
		"role": "Research Aide",
		"roles": {
			"Contributions to Tax Intelligence and Management Platform (TIMP)": ["Joined a top-tier development team responsible for the development and maintenance of TIMP, a critical Tax Intelligence and Management Platform.","Actively participated in the transition of the TIMP application from the XSC architecture to the XSA architecture, enhancing the platform's scalability and efficiency."],
			"CI-CD Pipeline Creation and Code Analysis": ["Key role in creating robust Continuous Integration and Continuous Deployment (CI-CD) pipelines for the SAP cloud platform using Jenkins, automating the deployment process and optimizing workflow.","Utilized static code analysis tools such as SonarQube and NodeJSScanner to identify vulnerabilities in the codebase, enabling proactive resolution and enhancing code quality."],
			"Technological Expertise": ["Proficiently worked with a technology stack including JS, Docker, XSJS, and Java, ensuring smooth integration and development within the TIMP project."]
		},
		"Key Achievements":["Internship tenure at Agile Solutions provided valuable exposure to enterprise-level projects and technologies, emphasizing the importance of efficient CI-CD pipelines and code analysis in software development.","Developed essential skills in system architecture migration and advanced tools, positioning me to transition into a full-fledged Software Engineer role."]
	}

	// const president = {
	// 	"company": "President at Mayavi Tech Club, K L University (July 2019 – March 2021)",
	// 	"role": "Research Aide",
	// 	"roles": {
	// 		"Establishment of Mayavi Tech Club": ["Founded the Mayavi Tech Club at K L University, with a specific focus on Augmented Reality, Virtual Reality, and Game Development.","Initiated the club's operations and ensured its smooth functioning, aiming to create an inclusive and engaging environment for technology enthusiasts."],
	// 		"Industry Engagement and Educational Initiatives": ["Forged a valuable association with Unity, a prominent technology company, to bolster the club's activities and educational outreach.","Organized and conducted numerous tech seminars and workshops, providing members with insights and hands-on experience in cutting-edge technologies."],
	// 		"Club Expansion and Representation": ["Elevated the Mayavi Tech Club's status, attracting a membership base of 150 enthusiastic individuals passionate about technology and innovation.","Represented the Mayavi Club in various hackathons, showcasing the club's talent and dedication on a broader platform."]
	// 	},
	// 	"Key Achievements":["The role of the President at Mayavi Tech Club nurtured leadership skills, honed organizational abilities, and deepened the understanding of emerging technologies.","Through this experience, gained expertise in effectively managing a team, organizing events, and fostering a collaborative learning environment."]
	// }

	const president = [
		{
			"text":"Founded the Mayavi Tech Club at K L University, with a specific focus on Augmented Reality, Virtual Reality, and Game Development.Initiated the club's operations and ensured its smooth functioning, aiming to create an inclusive and engaging environment for technology enthusiasts.", 
			"image": "https://storage.googleapis.com/portfolio-399909.appspot.com/Mayavi/Mayavi1.JPG"
		},
		{
			"text":"Forged a valuable association with Unity, a prominent technology company, to bolster the club's activities and educational outreach. Organized and conducted numerous tech seminars and workshops, providing members with insights and hands-on experience in cutting-edge technologies.",
			"image":"https://storage.googleapis.com/portfolio-399909.appspot.com/Mayavi/Mayavi2.jpg"
		},
		{
			"text":"Elevated the Mayavi Tech Club's status, attracting a membership base of 150 enthusiastic individuals passionate about technology and innovation.Represented the Mayavi Club in various hackathons, showcasing the club's talent and dedication on a broader platform.",
			"image": "https://storage.googleapis.com/portfolio-399909.appspot.com/Mayavi/Mayavi3.jpg" 
		},
		{
			"text": "The role of the President at Mayavi Tech Club nurtured leadership skills, honed organizational abilities, and deepened the understanding of emerging technologies. Through this experience, gained expertise in effectively managing a team, organizing events, and fostering a collaborative learning environment",
			"image": "https://storage.googleapis.com/portfolio-399909.appspot.com/Mayavi/mayavi4.jpeg"
		}
	]

	const peerMentor = {
		"company": "Student Peer Mentor at Department of CSE, K L University (Dec 2018 – Apr 2021)",
		"role": "Research Aide",
		"roles": {
			"Peer Mentorship and Academic Support": ["Served as a peer mentor for courses such as 'Operating Systems,' 'Analysis and design of algorithms,' and 'Cloud Computing,' encouraging collaborative learning among peers and aiding faculty during lab sessions.","Guided and assisted fellow students, promoting effective understanding and application of course material."],
			"Leadership in Cloud Computing Cohort": ["Took charge of the Cloud Computing cohort of peer mentors, overseeing lab activities, coordinating with faculty, and organizing schedules to ensure smooth lab operations.","Demonstrated strong leadership in seeking permissions, assigning responsibilities, and facilitating an environment conducive to learning and growth."]
		},
		"Key Achievements":["The role of a Student Peer Mentor honed essential leadership and communication skills, crucial for successful collaboration with peers and faculty.","Gained valuable experience in academic support and mentorship, contributing to personal and professional development."]
	}

	const studentAmbassador = {
		"company": "Student Ambassador at Unity, K L University (December 2018 – April 2021)",
		"role": "Research Aide",
		"roles": {
			"Brand Representation and Promotional Activities:": ["Represented Unity at K L University, serving as a Student Ambassador and promoting Unity technologies within the academic community.","Actively engaged in various promotional activities to create awareness and generate interest in Unity's offerings."],
			"Educational Initiatives and Knowledge Sharing:": ["Conducted enlightening sessions and workshops on game development, Augmented Reality, and Virtual Reality, leveraging the platform of the Mayavi Technology Club.","Facilitated knowledge sharing and hands-on experiences, contributing to the growth and proficiency of fellow students in cutting-edge technologies."]
		},
		"Key Achievements":["Successfully increased brand presence and awareness of Unity technologies among students and faculty members at K L University.","Contributed to the integration of practical skills and knowledge, empowering peers to explore and excel in the field of game development and AR/VR technologies."]
	}

	const {dark} = props;
	const myStyles = {
		color: dark ? '#ffffff' : '#000000'
	};
	const bgtextStyle = {
		color: dark ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
		zIndex: 1,
		position: 'absolute',
		top: -20
	};

	const headingStyle = {
		color: dark ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
	  }

	const workExp = [{
		"title": "Research Aide",
		"id": "workResearch",
		"period": "Feb 2023 - Present",
		"company": "University at Buffalo, Buffalo, New York, USA",
		"descriptions": [
			"Currently working as Research Aide on IIMS(Integrated Incident Management System) project associated with GDIT company.",
			"Developing the IIMS web application, aligning with client specifications using React JS for the UI and Node JS for the backend.",
			"Ensuring a seamless user experience across platforms by integrating the updated backend with the Android app and managing DevOps processes within a Linux environment for efficient deployment and maintenance."
		],
		"data": researchAide
	},{
		"title": "Software Engineer",
		"id": "workSoftware",
		"period": "Sept 2021 - July 2022",
		"company": "Agile Solutions, Banglore, India",
		"descriptions": [
			"After doing an internship for one year, I was offered a full-time role in Agile Solutions.",
			"Worked in creating a new tax estimating component in Tax Intelligence and management platform (TIMP)."
		],
		"data": softwareEngineer
	},
	{
		"title": "Software Engineer (Contract)",
		"id": "workContract",
		"period": "Oct 2021 - April 2022",
		"company": "Sinch, Remote",
		"descriptions": [
			"Worked as contract software engineer in Sinch from Agile Solutions ",
			"Worked in creating a mdule in micro frontend architecture Sinch Engagement platform frontend using React tech stack"
		],
		"data": contractEngineer
	},
		{
		"title": "Intern",
		"id": "workIntern",
		"period": "Sept 2020-Aug 2021",
		"company": "Agile Solutions, Banglore, India",
		"descriptions": [
			"Working as Full stack web developer and DevOps Intern at AgileSolutions , wore many hats and worked on many things like Sonar-Qube,NodeJsScanner , Delivary Units , XSJS to XSC migration"
		],
		"data": intern
	},{

		"title": "Student teaching assistant",
		"id": "workAssistant",
		"period": "2018-2021",
		"company": "K L University, Vijayawada, India",
		"descriptions": [
			"Mentored peers in 'Operating Systems,' 'Analysis and design of algorithms,' and 'Cloud Computing,' fostering collaborative learning and assisting faculty in lab work.",
			"Led the Cloud Computing cohort of peer mentors, managing lab operations, schedules, and providing support to faculty."
		],
		"data": peerMentor
	},{

		"title": "President at Mayavi Tech Club",
		"id":"workPresident",
		"period": "July 2019 – March 2021",
		"company": "K L University, Vijayawada, India",
		"descriptions": [
			"Founded and led the Mayavi Tech Club, focusing on Augmented Reality, Virtual Reality, and Game Development.",
			"Conducted seminars, workshops, and represented the club in hackathons, expanding the club's reach to 150 members."
		],
		"data": president,
		"image": true
	},{

		"title": "Student Ambassador at Unity, K L University:",
		"id": "workAmbas",
		"period": "December 2018-April 2020",
		"company": "Unity",
		"descriptions": [
			"Served as a Student Ambassador, representing Unity at K L University.",
			"Conducted informative sessions and workshops on game development and Augmented Reality/Virtual Reality through the Mayavi Technology Club."
		],
		"data": studentAmbassador
	}]

	const cardVariants = {
		hidden: {
			opacity: 0,
			x: '100vw'
		},
		visible: {
			opacity: 1,
			x: 0
		}
	  };

	const ResumeWrap = (props) => {
		const {period, title, company, descriptions, index, data, image, id} = props;
		const [open,setOpen] = useState(false);

		return (
			<>
				{image == false ?
				<DetailsTextDialog data={data} open={open} setOpen={setOpen} dark={dark}/>
				: <DetailsDialog  data={data} open={open} setOpen={setOpen} dark={dark}/>}
				<Reveal>
					<div id={id} onClick={() => {
					setOpen(true)
				}} className={dark == true ? "resume-wrap-dark" : "resume-wrap"} >
					<span className="date">{period}</span>
					<h2 style={myStyles}>{title}</h2>
					<span style={myStyles} className="position">{company}</span>
					<div style={{marginTop:'1.5rem'}}>
					{
						descriptions.map((description) => {
							return(
							<li style={myStyles}>{description}</li>
							)
						})
					}
					</div>
					<a style={{marginTop:'1.5rem'}} className="btn btn-primary py-3 px-4">Know more</a>
					</div>
				</Reveal>
			</>
		
		)
	}
  return (
    <section class="ftco-section ftco-no-pb" id="resume-section">
    	<div class="container">
    		<div class="row justify-content-center pb-5">
				<div class="col-md-10 heading-section text-center">
						<div>
							<h1 class="big big-2" style={bgtextStyle}>Work Experience</h1>
							<h2 class="mb-4" style={myStyles}>Work Experience</h2>
						</div>
				</div>
        	</div>
    		<div class="row">
    			<div class="col-md-12">
					{
						workExp.map((exp, index) => {
							return (
							<ResumeWrap id={exp.id} image={exp.image || false} data={exp.data} period = {exp.period} title={exp.title} company={exp.company} descriptions={exp.descriptions} index={index} />
							);
						})
					}
    			</div>
    		</div>
    	</div>
    </section>
  )
}
