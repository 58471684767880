import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import { Paper } from '@mui/material';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import vissu from '../images/vissu.jpg'
import {useMediaQuery} from '@mui/material';

export default function DetailsTextDialog(props) {
  const {open, setOpen, dark, data} = props;
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState(false);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMaxWidthChange = (event) => {
    setMaxWidth(
      // @ts-expect-error autofill of arbitrary value is not handled.
      event.target.value,
    );
  };

  const handleFullWidthChange = (event) => {
    setFullWidth(event.target.checked);
  };

  const SingleContent = (props) => {
    const {direction} = props
    const colourStyle = {
        color: dark ? '#ffffff' : '#000000'
      }
    return (
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <b><h2 style={colourStyle}>{data.company}</h2></b>
          </Grid>
          <Grid item xs={12} sm={12}>
                <u><h3 style={colourStyle}>Roles and Responsibilities:</h3></u>
                {
                    Object.keys(data.roles).map((role) => {
                        return (
                            <>
                                <b>{role}</b>
                                <ul>
                                {
                                    data.roles[role].map((item) => {
                                        
                                        return (
                                            <li>{item}</li>
                                        )
                                    })
                                }
                                </ul>
                            </>
                        )
                    })
                }
                <u><h3 style={colourStyle}>Key Acheivements</h3></u>
                {
                    <ul>{
                    data["Key Achievements"].map((achievement) => {
                        return (
                            <>
                                <li>{achievement}</li>
                            </>
                        )
                    })
                }
                    </ul>
                }
          </Grid>
        </Grid>
      </Box>
    )
  }

  const colourStyle = {
    color: dark ? '#ffffff' : '#000000'
  }
  const myStyles = {
    background : dark ? "linear-gradient(to right , #1a1a1a 50%, #994d00)" : "linear-gradient(#ffffcc, #ffc107)",
    fontFamily:'"Poppins", Arial, sans-serif' 
  }

  return (
    <>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <Paper sx={myStyles}>
          <DialogTitle sx={colourStyle}>More Information</DialogTitle>
          <DialogContent sx={colourStyle}>
              {dark ? <hr style={{border: 'none',height: '1px',backgroundColor:'#e5e5e5'}}/> : <hr/>}
             <SingleContent/>
             {dark ? <hr style={{border: 'none',height: '1px',backgroundColor:'#e5e5e5'}}/> : <hr/>}
            
          </DialogContent>
          <DialogActions>
            <Button sx={colourStyle} onClick={handleClose}>Close</Button>
          </DialogActions>
        </Paper>
      </Dialog>
    </>
  );
}
