import React from 'react'
import vissu from  '../images/vissu.jpg'
import Reveal from '../components/Reveal'

export default function Aboutme(props) {
  const {dark} = props
  const myStyles = {
    color: dark ? '#ffffff' : '#000000',
  };
  const headingStyle = {
    color: dark ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
  }
  const bgtextStyle = {
		color: dark ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
		zIndex: 1,
		position: 'absolute',
		top: -30
	};
  console.log(vissu)
  return (
      <section className="ftco-about img ftco-section ftco-no-pb" id="about-section">
      <div className="container">
      <hr/>
      <Reveal>
          <div className="row d-flex">
              <div className="col-md-6 col-lg-5 d-flex">
                  <div className="img-about img d-flex align-items-stretch">
                      <div className="overlay"></div>
                      <div className="img d-flex align-self-stretch align-items-center" style={{backgroundImage:`url(https://storage.googleapis.com/portfolio-399909.appspot.com/Hashinclude/MainDP.jpeg)`,margin:'10px 0px 100px 0px'}}>
                      </div>
                  </div>
              </div>
              <div className="col-md-6 col-lg-7 pl-lg-5 pb-5">
                  <div className="row justify-content-start pb-3">
                <div style={{position:'relative'}} className="col-md-12 heading-section">
                    <div>
                    <h1 className="big" style={bgtextStyle}>About</h1>
                    <h2 className="mb-4" style={myStyles}>About Me</h2>
                    </div>
                  <p style={myStyles}>I am Viswanath Matukumalli, currently pursuing a Master of Science in Computer Science at the University at Buffalo.</p>
                  <ul className="about-info mt-4 px-md-0 px-2">
                      <li style={myStyles} className="d-flex"><span>Name:</span> <span>Matukumalli Viswanath</span></li>
                      <li style={myStyles} className="d-flex"><span>Date of birth:</span> <span>March 10, 2000</span></li>
                      <li style={myStyles} className="d-flex"><span>Address:</span> <span>56 Englewood Ave, Buffalo</span></li>
                      <li style={myStyles} className="d-flex"><span>Email:</span> <span>mlnsviswanath@gmail.com</span></li>
                      <li style={myStyles} className="d-flex"><span>Phone: </span> <span>+1 7166178053</span></li>
                  </ul>
                  <h2 className="mb-4" style={myStyles}>Key Activities</h2>
                  <div
                   className="about-info mt-4 px-md-0 px-2">
                      <li style={myStyles}>Research Asssitant at University at Buffalo</li>
                      <li style={myStyles}>Student peer mentor</li>
                      <li style={myStyles}>Founder and President of Maavi tech club(AR/Vr/Gaming)</li>
                      <li style={myStyles}>Lead peer mentor for Cloud Computing Cohorot</li>
                      <li style={myStyles}>Unity student ambassador</li> 
                      <li style={myStyles}>Publicity core lead in Hash Include 2020</li>
                      <li style={myStyles}>Radio Jockey at K L Radio</li>
                      <li style={myStyles}>Technical member of Software Engineer Associate club</li>
                  </div>	
                  
                </div>
              </div>
            <div className="counter-wrap d-flex mt-md-3">
            <div className="text">
              <p><a href="ViswanathMatukumalliResume.pdf" className="btn btn-primary py-3 px-3">Download Resume</a></p>
            </div>
            </div>
              </div>
          </div>
          </Reveal>
          <hr/>
      </div>
    </section>
  )
}
