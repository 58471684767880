import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import DetailsDialog from './DetailsDialog';
import { useState } from 'react';
import Reveal from './Reveal';

export default function TextCard(props) {
    const {dark, data} = props
    const [open,setOpen] = useState(false);

    const colorStyle = {
        backgroundColor: dark ? 'rgba(255, 255, 255, 0.1)' : 'rgba(255, 255, 255, 0.3)', 
        border: dark ? '' : '1px solid #ffbd39',
        color: 'white'
    }

    const myStyle = {
        color: dark ? '#ffffff' : '#000000'
    }
      
  return (
    <Reveal>
        <DetailsDialog data={data?.data} dark={dark} open={open} setOpen={setOpen}/>
        <Card id={data?.id} sx={colorStyle} onClick= {() => {
            setOpen(true)
        }}>
    <CardActionArea>
        <CardContent>
        <Typography variant="body1" style={myStyle}>
            {data?.title}
        </Typography>
        <a style={{marginTop:'0.5rem'}} className="btn btn-primary py-2 px-3">Know more</a>

        </CardContent>
    </CardActionArea>
        </Card>
    </Reveal>
   
  );
}
