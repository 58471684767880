import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import ProjectCard from '../components/ProjectCard';
import Grid from '@mui/material/Grid';


export default function ProjectDisplay(props) {
  const {dark} = props;
  const myStyle = {
    color: dark ? '#ffffff' : '#000000'
  }

  const projects = [{
    "title":"Ecommerce Website for Boutique Store",
    "id": "projectEcommerce",
    "thumbnail":"https://storage.googleapis.com/portfolio-399909.appspot.com/Ecommerce/Dashboard.png",
    "description": "I have successfully developed a dynamic Ecommerce website tailored for a boutique store, empowering the store owner with a comprehensive and intuitive platform for managing orders, monitoring sales, and enhancing customer interactions. The project encompasses a user-friendly interface with a centralized dashboard, an efficient order management system, a customer module, and a product catalog with customizable options.",
    "features": [{
      "text": "The dashboard offers an overview of critical business metrics, including the number of pending orders, completed orders, ongoing orders, trending products, and sales graphs. It provides a snapshot of the store's performance and aids in decision-making.",
      "image": "https://storage.googleapis.com/portfolio-399909.appspot.com/Ecommerce/Dashboard.png"
    },{
      "text":"The platform includes a dedicated orders page, enabling the owner to view and manage all orders efficiently. The owner can update the status of each order and modify the status of individual items within a specific order. Additionally, the owner can download invoices directly from this page.",
      "image": "https://storage.googleapis.com/portfolio-399909.appspot.com/Ecommerce/OrderDetails.png"
    },{
      "text": "The order deatils can also be seen by expandin each order",
      "image": "https://storage.googleapis.com/portfolio-399909.appspot.com/Ecommerce/OrderDetailsExpand.png"
    },{
      "text": "The customer module allows the owner to add and maintain customer information, including names, email addresses, and phone numbers. This facilitates personalized communication and streamlined order processing.",
      "image": "https://storage.googleapis.com/portfolio-399909.appspot.com/Ecommerce/Customers.png"
    },{
      "text": "Customer details can also be edited",
      "image": "https://storage.googleapis.com/portfolio-399909.appspot.com/Ecommerce/EditCustomer.png"
    },{
      "text": "The catalog page showcases all available products, offering a detailed view of each item in fullscreen mode.",
      "image":"https://storage.googleapis.com/portfolio-399909.appspot.com/Ecommerce/Catalog.png"
    },{
      "text": "The owner can easily customize products with text and create orders directly from the catalog, selecting multiple items and quantities.",
      "image": "https://storage.googleapis.com/portfolio-399909.appspot.com/Ecommerce/SeeProduct2.png"
    },
    {
      "text": "The owner can also see the product in full screen mode he can add customisattions to product",
      "image": "https://storage.googleapis.com/portfolio-399909.appspot.com/Ecommerce/SeeProduct2.png"
    },
    {
      "text": "When creating an order, the owner can quickly retrieve customer details by entering the customer's phone number",
      "image": "https://storage.googleapis.com/portfolio-399909.appspot.com/Ecommerce/CreateOrder.png"
    },
    {
    "text": "The owner can download the invoices of each order and can send it to customers",
    "image":"https://storage.googleapis.com/portfolio-399909.appspot.com/Ecommerce/Invoice.png"
    }]
  },{
    "title": "Job Platform Development: Empowering Candidates and Employers for Seamless Recruitment Process",
    "id": "projectJob",
    "description":"In this portfolio project, I have designed and developed a comprehensive job platform tailored to address the needs of both candidates seeking employment opportunities and employers searching for suitable talent. The platform streamlines the recruitment process through two distinct logins: one for candidates and another for employers.",
    "thumbnail":"https://storage.googleapis.com/portfolio-399909.appspot.com/CoInitiator/thumbnail.png",
    "features":[{
      "text":"Candidate login page, This page allows candidates to log in to the platform using their credentials. It showcases a clean and user-friendly interface for login, reinforcing security and ease of access.",
      "image": "https://storage.googleapis.com/portfolio-399909.appspot.com/CoInitiator/login2.png"
    },{
      "text": "Employee login, Similar to the candidate login page, this page provides access to employers, allowing them to log in using their credentials. It ensures a secure login process specific to employers.",
      "image":"https://storage.googleapis.com/portfolio-399909.appspot.com/CoInitiator/login1.png"
    },{
      "text":"Home page, The home page is the landing page of the job platform, providing an overview of the platform's offerings and encouraging visitors to log in or sign up as either a candidate or an employer.",
      "image":"https://storage.googleapis.com/portfolio-399909.appspot.com/CoInitiator/thumbnail.png"
    },{
      "text":"Job search page, The job search page enables candidates to search for job listings based on criteria such as location and salary. It provides a user-friendly interface for finding suitable job opportunities.",
      "image":"https://storage.googleapis.com/portfolio-399909.appspot.com/CoInitiator/jobSearch.png"
    },{
      "text": "Job deatils page, This page displays detailed information about a specific job listing, including job description, requirements, and desired skills. It assists candidates in making informed decisions about applying for a job.",
      "image": "https://storage.googleapis.com/portfolio-399909.appspot.com/CoInitiator/aboutJob.png"
    },{
      "text":"Skill page, The skill page guides candidates to take skill exams and acquire necessary skills for job applications. It offers a straightforward process for skill assessment and skill improvement.",
      "image":"https://storage.googleapis.com/portfolio-399909.appspot.com/CoInitiator/skillexam.png"
    },{
      "text":"Coding assesment page, This page allows candidates to write code in multiple languages and submit assessments for evaluation. It incorporates a text editor, instructions, and a submission process.",
      "image":"https://storage.googleapis.com/portfolio-399909.appspot.com/CoInitiator/codingPlatform.png"
    },{
      "text": "Cadidate profile page, The profile page allows candidates and employers to view and edit their profile information, including contact details, skills, and other relevant information.",
      "image": "https://storage.googleapis.com/portfolio-399909.appspot.com/CoInitiator/profile.png"
    },{
      "text": "Track page, The track page is an essential feature that allows candidates to monitor and track the status of their job applications. It provides candidates with an overview of the progress of their applications",
      "image": "https://storage.googleapis.com/portfolio-399909.appspot.com/CoInitiator/Track.png"
    },{
      "text": "This three-step page guides employers through the process of posting a job, including adding job details, defining coding questions, and setting test cases for candidate evaluation.",
      "image": "https://storage.googleapis.com/portfolio-399909.appspot.com/CoInitiator/postJob.png"
    },{
      "text": "Coding questions desigining",
      "image": "https://storage.googleapis.com/portfolio-399909.appspot.com/CoInitiator/assignCoding.png"
    },{
      "text": "Testcases assignment page, where employer assigns testcases which will be tested on users code",
      "image": "https://storage.googleapis.com/portfolio-399909.appspot.com/CoInitiator/testCases.png"
    },{
      "text": "Job posting page,  Employers use this page to view and manage their job postings. It displays an organized list of job postings along with details such as candidate applications, profiles, and coding assessment results.",
      "image": "https://storage.googleapis.com/portfolio-399909.appspot.com/CoInitiator/JobPostings.png"
    }]
  },{
    "title": "Health360: Empowering Medical Research with Interactive Clinical Trials Module",
    "id": "projectHealth",
    "description": '"Health360" is an innovative Flutter-based mobile application designed to revolutionize healthcare by empowering medical research and user participation in clinical trials. As the developer of a critical module within this application, I have crafted a seamless and intuitive Clinical Trials Management System to connect doctors and users, fostering valuable data collection and analysis for medical advancements.',
    "thumbnail": "https://storage.googleapis.com/portfolio-399909.appspot.com/health360/health2.png",
    "features": [{
      "text": "Developed a comprehensive module enabling doctors to create and manage clinical trials within the app.Integrated React to build an efficient Doctor Portal, facilitating seamless clinical trial administration.",
      "image":"https://storage.googleapis.com/portfolio-399909.appspot.com/health360/health6.png"
    },{
      "text": "Implemented push notification functionality, enabling doctors to notify users about new clinical trials and engage them effectively. Users receive real-time notifications, promoting active participation in clinical trials.",
      "image": "https://storage.googleapis.com/portfolio-399909.appspot.com/health360/health5.png"
    },{
      "text": "Engineered a system allowing users to express interest and participate in clinical trials by approving their involvement. Users can choose to be a part of a trial, demonstrating an interactive approach to user engagement.",
      "image": "https://storage.googleapis.com/portfolio-399909.appspot.com/health360/health2.png"
    }, {
      "text": "Designed a user-friendly interface for doctors to create questionnaires and gather critical data from participating users. Enabled doctors to categorize users into various groups based on their responses and other user information.",
      "image": "https://storage.googleapis.com/portfolio-399909.appspot.com/health360/health3.png"
    }, {
      "text": "Enabled doctors to download user response data in CSV format for in-depth analysis and research purposes, promoting evidence-based decision-making.",
      "image": "https://storage.googleapis.com/portfolio-399909.appspot.com/health360/health4.png"
    }],
  },{
    "title": "Real-Time Chat Application with End-to-End Encryption",
    "id": "projectChat",
    "description": " I have crafted an innovative real-time chat application using React and Firebase, designed to prioritize security and seamless communication. The application employs Google account authentication, ensuring a secure and user-friendly login process. Once authenticated, users gain access to a dynamic platform that allows them to engage in real-time conversations while maintaining the utmost privacy through AES encryption.",
    "thumbnail":"https://storage.googleapis.com/portfolio-399909.appspot.com/RealTimeChat/chat.png",
    "features": [{
      "text": "Users log in securely using their Gmail accounts, leveraging Google authentication for a seamless and trustworthy login experience.",
      "image": "https://storage.googleapis.com/portfolio-399909.appspot.com/RealTimeChat/chatlogin.png"
    },{
      "text": "The application enables users to engage in real-time conversations, view all their chats, and search for specific users to initiate or continue conversations effortlessly.",
      "image":"https://storage.googleapis.com/portfolio-399909.appspot.com/RealTimeChat/chat.png"
    },{
      "text": "Employed AES (Advanced Encryption Standard) algorithm to encrypt text messages before saving them in the database. Decryption of messages occurs solely at the client's end, maximizing the security and privacy of communication.",
      "image": "https://storage.googleapis.com/portfolio-399909.appspot.com/RealTimeChat/encryption.png"
    },{
      "text": "The user interface is intuitive and easy to navigate, providing a seamless experience for users to manage their conversations and engage with contacts.",
      "image": "https://storage.googleapis.com/portfolio-399909.appspot.com/RealTimeChat/chat2.png"
    },{
      "text": "Integrated Firebase as the backend database, enabling efficient storage and retrieval of encrypted chat messages in real-time. By developing this real-time chat application with end-to-end encryption, I have demonstrated proficiency in creating secure and user-centric communication platforms. The project underscores my commitment to implementing advanced security measures to protect user data while ensuring a smooth and efficient chat experience. Its fusion of cutting-edge technology and user-friendly design is a testament to my capabilities in developing secure and engaging applications.",
      "image": "https://storage.googleapis.com/portfolio-399909.appspot.com/RealTimeChat/chat.png"
    }]
  },{
    "title": "Terminal-Based Chat Application with Socket Programming in C",
    "thumbnail":"https://storage.googleapis.com/portfolio-399909.appspot.com/Terminalchat/bufferMain.png",
    "description": "In this notable project during my academic tenure at the University at Buffalo, I successfully engineered a terminal-based chat application employing Socket Programming in the C language. This endeavor showcased my adeptness in network programming, system operations, and real-time communication systems.",
    "id": "projectSocket",
    "features": [{
      "text": "Startup Setup: Implemented a setup mechanism that configures and initializes the application upon startup, ensuring smooth execution and proper functioning of the chat environment.",
      "image":"https://storage.googleapis.com/portfolio-399909.appspot.com/Terminalchat/startup.png"
    },{
      "text": "IP Retrieval:Developed a feature to retrieve the IP address of the local system, providing users with essential network information for connection.",
      "image": "https://storage.googleapis.com/portfolio-399909.appspot.com/Terminalchat/ip.png"
    },{
      "text": "PORT Information: Created a functionality to display the port on which the application is currently running, aiding users in establishing connections and ensuring seamless communication.",
      "image":"https://storage.googleapis.com/portfolio-399909.appspot.com/Terminalchat/port.png"
    }, {
      "text": "Login Functionality:Implemented a robust login system allowing CLIENT systems to log in to the SERVER system, establishing a secure entry point for users to access the chat application.",
      "image":"https://storage.googleapis.com/portfolio-399909.appspot.com/Terminalchat/exception_login.png"
    },{
      "text":"Active Client List Display (LIST):Integrated a login mechanism that enables the SERVER to send a list of active clients to the CLIENT upon successful login, providing visibility into active users.",
      "image": "https://storage.googleapis.com/portfolio-399909.appspot.com/Terminalchat/list.png"
    },{
      "text": "Refresh Active Client List (REFRESH):Implemented a command to refresh the list of active clients, ensuring users have the latest and most accurate information regarding active participants.",
      "image": "https://storage.googleapis.com/portfolio-399909.appspot.com/Terminalchat/list.png"
    },{
      "text": "Logout (EXIT):Enabled clients to log out gracefully from the server, terminating their connection and ensuring a secure exit from the chat application.",
      "image": "https://storage.googleapis.com/portfolio-399909.appspot.com/Terminalchat/exit.png"
    },{
      "text": "Message Sending (SEND):Created a mechanism allowing clients to send messages to specific clients using their IP addresses, enabling direct communication between users.",
      "image": "https://storage.googleapis.com/portfolio-399909.appspot.com/Terminalchat/send.png"
    },{
      "text": "Broadcast Messaging (BROADCAST):Implemented a feature to broadcast messages, enabling clients to send messages to all active clients simultaneously, enhancing group communication.",
      "image": "https://storage.googleapis.com/portfolio-399909.appspot.com/Terminalchat/broadcast.png"
    },{
      "text": "Message Statistics (STATISTICS):Developed a functionality to track and display statistics related to sent and received messages by each client, providing insights into message activity.",
      "image": "https://storage.googleapis.com/portfolio-399909.appspot.com/Terminalchat/statistics.png"
    },{
      "text": "Blocking Users (BLOCK):Enabled clients to block specific users, preventing them from receiving messages from the blocked users, enhancing control and privacy.",
      "image": "https://storage.googleapis.com/portfolio-399909.appspot.com/Terminalchat/block.png"
    },{
      "text": "Unblocking Users (UNBLOCK):Created a feature allowing clients to unblock previously blocked users, restoring communication with those users.",
      "image": "https://storage.googleapis.com/portfolio-399909.appspot.com/Terminalchat/matter.png"
    },{
      "text": "Blocked Users List (BLOCKED):Implemented a command to display the list of users blocked by the current client, offering transparency and control over blocked users.",
      "image": "https://storage.googleapis.com/portfolio-399909.appspot.com/Terminalchat/blocked.png"
    },{
      "text": "Message Buffering (BUFFER):Implemented a buffer mechanism to save messages and display them when a client logs in, ensuring no important messages are missed while offline.",
      "image": "https://storage.googleapis.com/portfolio-399909.appspot.com/Terminalchat/bufferMain.png"
    }]
  },{
    "title": "Augmented Reality Assistant: Enhancing User Interaction through Cloud Computing",
    "id": "projectAR",
    "description": "This project represents a remarkable fusion of cutting-edge technologies, showcasing my ingenuity in creating an Augmented Reality Assistant using Cloud Computing. Developed during my tenure at K L University, this endeavor presents an innovative and engaging approach to addressing Covid-related queries, enhancing user interaction through augmented reality and published the research paper .",
    "thumbnail":"https://storage.googleapis.com/portfolio-399909.appspot.com/ARChatbot/chabohome.png",
    "features": [{
      "text": "Crafted a human interactive chatbot using Augmented Reality (AR), enabling users to engage with a 3D model person for an immersive and interactive experience.",
      "image": "https://storage.googleapis.com/portfolio-399909.appspot.com/ARChatbot/chatbo1.png"
    },{
      "text": "Provided a platform for users to ask Covid-related questions, with responses generated by the 3D model person, a novel approach compared to traditional text-based chatbots.",
      "image": "https://storage.googleapis.com/portfolio-399909.appspot.com/ARChatbot/chabohome.png"
    },{
      "text": "Leveraged a robust technology stack comprising Unity for AR development, ARCore for augmented reality capabilities, and Google Cloud Platform for cloud-based assistance.",
      "image": "https://storage.googleapis.com/portfolio-399909.appspot.com/ARChatbot/chabo2.png"
    },{
      "text": "Successfully published a research paper titled. Augmented Reality Assistant for Covid-Related Queries,adding academic value to the project and contributing to the broader scientific community.",
      "image": "https://storage.googleapis.com/portfolio-399909.appspot.com/ARChatbot/chabo3.png"
    }]
  },{
    "title": "Comparative Analysis of Encryption Algorithms",
    "id": "projectAlgo",
    "description": "This project represents an in-depth exploration of encryption algorithms and their performance across cloud and local environments. Conducted during my academic tenure at K L University, the study delves into the comparative analysis of encryption algorithms such as 'Blowfish,' 'AES,' and 'DES,' taking into account crucial parameters like processing time and file size. The results were meticulously analyzed and published in a comprehensive research paper.",
    "thumbnail": "https://storage.googleapis.com/portfolio-399909.appspot.com/cryptoAnalysis/cryptoHome.png",
    "features": [{
      "text":"Selected and extensively studied three prominent encryption algorithms, namely 'Blowfish,' 'AES,' and 'DES,' due to their widespread use and significance in data security.",
      "image": "https://storage.googleapis.com/portfolio-399909.appspot.com/cryptoAnalysis/AES.png"
    },{
      "text":"Conducted experiments to compare the performance of these algorithms in both cloud-based and local (offline) environments, aiming to evaluate their efficiency and effectiveness in diverse settings.",
      "image":"https://storage.googleapis.com/portfolio-399909.appspot.com/cryptoAnalysis/DES.png"
    },{
      "text": "Focused on critical parameters such as processing time and file size to measure and compare the algorithms' performance under varying circumstances.",
      "image": "https://storage.googleapis.com/portfolio-399909.appspot.com/cryptoAnalysis/BLOWFISH.png"
    },{
      "text": "Utilized Java, a versatile and widely adopted programming language, to implement the encryption algorithms and conduct experiments in both cloud and local environments.",
      "image":"https://storage.googleapis.com/portfolio-399909.appspot.com/cryptoAnalysis/main.png"
    },{
      "text":"Authored a comprehensive research paper presenting the analytical study, experimental methodology, results, and insightful conclusions drawn from the experiments.",
      "image": "https://storage.googleapis.com/portfolio-399909.appspot.com/cryptoAnalysis/DESGraph.png"
    }]
  },{
    "title": "Augmented Reality, Virtual Reality and Gaming Portfolio",
    "id": "projectMayavi",
    "description": "Within the vibrant domain of Augmented Reality (AR) and Virtual Reality (VR) applications, I have dedicated significant time and effort to craft immersive experiences that resonate with users. My tenure at Mayavi Tech Club, from August 2019 to March 2020, was a remarkable journey filled with exciting projects that showcase my creativity and technical expertise.",
    "thumbnail": "https://storage.googleapis.com/portfolio-399909.appspot.com/Mayavi/Home.jpg",
    "features": [{
      "text":"Corona Survivor:Designed an engaging AR game where users navigate a city affected by a pandemic, offering an interactive and thrilling escape experience.",
      "video": "https://storage.googleapis.com/portfolio-399909.appspot.com/mayaviPortfolio/coronaSurviver.mp4" 
    },{
      "text": "Anywhere Door:Developed an AR application using ARCore and Blender, allowing users to virtually travel to desired locations through a magical door placed in real-life settings.",
      "video": "https://storage.googleapis.com/portfolio-399909.appspot.com/mayaviPortfolio/anyWhereDoor.mp4"
    },{
      "text": "Virtual Convocation:Created a virtual convocation experience, providing a 3D simulation where students collect their degree certificates from the Vice-Chancellor, fostering a sense of achievement and celebration.",
      "video":"https://storage.googleapis.com/portfolio-399909.appspot.com/mayaviPortfolio/virtualConvocation.mp4"
    },{
      "text": "Virtual Museum:Crafted an immersive VR application providing users with a guided tour of a museum, complete with voice assistance, enhancing the understanding of exhibited artifacts.",
      "video": ""
    },{
      "text": "HashInclude Mascot:Brought a 3D mascot to life using ARCore, effectively promoting HashInclude, the CSE branch fest, by blending the digital and real worlds seamlessly.",
      "video":"https://storage.googleapis.com/portfolio-399909.appspot.com/mayaviPortfolio/hashIncludeMascot.mp4"
    },{
      "text": "Mayavi Promotion Video:Produced a captivating cinematic animation video utilizing Unity, effectively promoting Mayavi and showcasing the club's vision and objectives.",
      "video": "https://storage.googleapis.com/portfolio-399909.appspot.com/mayaviPortfolio/mayaviPromo.mp4"
    },{
      "text": "Virtual Campus Tour:Designed a comprehensive 360-degree virtual tour application, offering an immersive glimpse into the KL University campus, enriching the user experience.",
      "video": ""
    }]

  },{
    "title": "K L Radio App: Stream KL Radio Anytime, Anywhere",
    "id": "projectKL",
    "description": 'The "K L Radio" project represents a successful foray into mobile application development, where I conceptualized and built an Android app that brings the essence of KL Radio to the fingertips of its listeners. This app, available on the Play Store, empowers users to enjoy the live stream of KL Radio, a platform that embraces music, entertainment, and engagement.',
    "thumbnail":"https://storage.googleapis.com/portfolio-399909.appspot.com/KLRadio_up/thumbnail.png",//"https://storage.googleapis.com/portfolio-399909.appspot.com/KLRadio/radio1.jpg",
    "features": [{
      "text":"Integrated live streaming functionality, enabling users to seamlessly stream KL Radio within the app, ensuring an uninterrupted and immersive listening experience.",
      "image": "https://storage.googleapis.com/portfolio-399909.appspot.com/KLRadio_up/klradio1.png"
    },{
      "text":"Designed an intuitive and engaging user interface, making it easy for users to navigate the app, select their preferred radio channels, and control playback effortlessly.",
      "image": "https://storage.googleapis.com/portfolio-399909.appspot.com/KLRadio_up/klradio2.png"
    },{
      "text":"Successfully published the app on the Play Store, making it readily accessible to a wider audience and showcasing my ability to deploy Android applications for public use.",
      "image": "https://storage.googleapis.com/portfolio-399909.appspot.com/KLRadio_up/klradio3.png"
    }]
  },{
    "title": "Heart Disease Predictor: Bridging R and React for Health Insights",
    "id": "projectHeart",
    "description": "In this innovative project, I amalgamated the power of R and React to develop a Heart Disease Predictor, showcasing my expertise in machine learning and web application development. Leveraging the 'heart_cleveland_upload.csv' dataset, I trained a predictive model using Random Forest, allowing users to assess their heart health.",
    "thumbnail":"https://storage.googleapis.com/portfolio-399909.appspot.com/HeartDisease/Home.png",
    "features": [{
      "text":"Utilized the 'heart_cleveland_upload.csv' dataset to perform in-depth data analysis, identifying critical features for heart disease prediction. Employed Random Forest, a robust machine learning algorithm, to train an accurate model capable of predicting the presence or absence of heart disease.",
      "image":"https://storage.googleapis.com/portfolio-399909.appspot.com/HeartDisease/Home.png"
    },{
      "text": "Leveraged the Plumber package in R to create a streamlined API, ensuring efficient communication between the frontend and backend components. The API seamlessly processes user-provided parameters and feeds them to the predictive model for real-time predictions.",
      "image": "https://storage.googleapis.com/portfolio-399909.appspot.com/HeartDisease/Home.png"
    },{
      "text": "Developed an engaging and user-friendly frontend application using React, enabling users to input relevant health parameters for heart disease prediction.",
      "image": "https://storage.googleapis.com/portfolio-399909.appspot.com/HeartDisease/Negative.png"
    },{
      "text": "Integrated a responsive interface allowing users to easily interact with the application and obtain predictions regarding their heart health.",
      "image": "https://storage.googleapis.com/portfolio-399909.appspot.com/HeartDisease/Positive.png"
    }]
  }]

  const bgtextStyle = {
		color: dark ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
		zIndex: 1,
		position: 'absolute',
		top: -20
	};
  return (
    <div className="container" id="projects-section">
       <hr/>
        <div class="row justify-content-center pb-5">
            <div className="col-md-12 heading-section text-center">
                <h1 className="big big-2" style={bgtextStyle}>Projects</h1>
                <h2 className="mb-4" style={myStyle}>My Projects</h2>
            </div>
        </div>
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        {
          projects?.map((project) => {
            return (
              <>
                 <Grid item xs={12} sm={6}>
                  <ProjectCard project={project} dark={dark}  direction="left"/>
                 </Grid>
              </>
            )
          })
        }
      </Grid>
    </Box>
    </div>
  );
}
