import React from 'react'
import { useEffect, useState, useRef } from 'react'
import { motion, useInView, useAnimation, useIsPresent } from 'framer-motion'

export default function Reveal({children}) {
    console.log(children)
    const ref = useRef(null);
    const isInView = useInView(ref, {once : true})

    const mainControls = useAnimation()

    useEffect(() => {
        console.log(isInView)
       if(isInView){
        mainControls.start("visible")
       }
    },[isInView])

  return (
    <div ref={ref}>
        <motion.div
            variants={{
                hidden: {opacity: 0},
                visible: {opacity:1}
            }}
            initial="hidden"
            whileHover={{
                transition: {type: 'spring', duration: 0.3 },
            }}
            animate= {mainControls}
            transition={{type: 'spring', duration: 2.0 , delay: 0.5}}
        >
            {children}
        </motion.div>
    </div>
  )
}
