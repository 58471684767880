import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import { Paper } from '@mui/material';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import vissu from '../images/vissu.jpg'
import {useMediaQuery} from '@mui/material';

export default function DetailsDialog(props) {
  const {open, setOpen, dark, data, title} = props;
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState(false);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMaxWidthChange = (event) => {
    setMaxWidth(
      // @ts-expect-error autofill of arbitrary value is not handled.
      event.target.value,
    );
  };

  const handleFullWidthChange = (event) => {
    setFullWidth(event.target.checked);
  };

  const SingleContent = (props) => {
    const {direction, data} = props
    return (
      direction == "left" ?
      isSmallScreen == false ?  <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            {data.image ? <img style={{maxWidth:'100%', height:'auto'}} src={data.image}/> : <video style={{width:'50rem',maxWidth:'100%', height:'auto'}} controls>
            <source src={data.video} type="video/mp4"/>
            Your browser does not support the video tag.
          </video>}
          </Grid>
          <Grid item xs={12} sm={8}>
                {data.text}
              {data.link ? <a href={data.link}>{data.link}</a> : null}
          </Grid>
        </Grid>
      </Box> :
      
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
          <>
            <div>
              {data.text}
            </div>
            <div>
              {data.link ? <a href={data.link}>{data.link}</a> : null}
            </div>
          </>
          </Grid>
          <Grid item xs={12} sm={4}>
          {data.image ? <img style={{maxWidth:'100%', height:'auto'}} src={data.image}/> : <video style={{width:'50rem',maxWidth:'100%', height:'auto'}} controls>
            <source src={data.video} type="video/mp4"/>
            Your browser does not support the video tag.
          </video>}
          </Grid>
        </Grid>
      </Box>
      :

  <Box>
  <Grid container spacing={2}>
    <Grid item xs={12} sm={8}>
      <>
        <div>
          {data.text}
        </div>
        <div>
          {data.link ? <a href={data.link}>{data.link}</a> : null}
        </div>
      </>
    </Grid>
    <Grid item xs={12} sm={4}>
    {data.image ? <img style={{maxWidth:'100%', height:'auto'}} src={data.image}/> : <video style={{width:'50rem',maxWidth:'100%', height:'auto'}} controls>
            <source src={data.video} type="video/mp4"/>
            Your browser does not support the video tag.
          </video>}
    </Grid>
  </Grid>
  </Box> 

    )
  }

  const colourStyle = {
    color: dark ? '#ffffff' : '#000000'
  }
  const myStyles = {
    background : dark ? "linear-gradient(to right , #1a1a1a 50%, #994d00)" : "linear-gradient(#ffffcc, #ffc107)",
    fontFamily:'"Poppins", Arial, sans-serif' 
  }

  return (
    <>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <Paper sx={myStyles}>
          <DialogTitle sx={colourStyle}>{title}</DialogTitle>
          <DialogContent sx={colourStyle}>
            {
              data?.map((eachData, index) => {
                return(
                  <>
                  <hr/>
                    <SingleContent direction = {index%2 ==0 ? "right" : "left"} data={eachData}/>
                  </>
                )
              })
            }
          </DialogContent>
          <DialogActions>
            <Button sx={colourStyle} onClick={handleClose}>Close</Button>
          </DialogActions>
        </Paper>
      </Dialog>
    </>
  );
}
