import React from 'react'
import Reveal from '../components/Reveal'


export default function Intro(props) {
  const {dark} = props;
  const myStyles = {
    color: dark ? '#ffffff' : '#000000'
  };
  const bottonStyle = {
    color: dark ? '#ffffff' : '#000000',
    border: dark ? '1px solid #ffffff' : '1px solid #000000'
  }

  const downloadResume = () => {
    const resumeFilePath = 'https://storage.googleapis.com/portfolio-399909.appspot.com/ViswanathMatukumalli_Resume.pdf';
    const link = document.createElement('a');
    link.href = resumeFilePath;
    link.target = '_blank'; 
    link.download = 'ViswanathMatukumalliResume.pdf';
    link.click();
  }
  return (
      <section style={{padding:'0rem 0rem 7rem 0rem'}} id="intro" className="ftco-section">
        <div className="container" >
          <hr/>
          <div className="row justify-content-center mb-5 pb-5">
            <div className="col-md-7 heading-section text-center ftco-animate">
            </div>
          </div>
            <div className="vissu">
              {/* <Reveal> */}
                <span style = {{color: "#ffbd39"}} className="subheading">Hello!</span>
                <h1 className={dark == true ? '' : 'intro-light'} style={{fontWeight: 700, fontSize: "50px"}}>I'm</h1>
                <h1 style={{fontWeight:700,fontSize:"50px",color:"#ffbd39"}}>Viswanath Matukumalli</h1>
                <h2 className="mb-4" style={myStyles} >University at Buffalo, CSE Masters graduate '24</h2>
                <p><button onClick = {() => {downloadResume()}} className="btn btn-primary py-3 px-4">My Resume</button> <a href="#projects-section" style={bottonStyle} className="btn py-3 px-4">My works</a></p>
              {/* </Reveal> */}
            </div>
        </div>
    </section>		
  )
}
