import React from 'react'
import Reveal from '../components/Reveal';

export default function Skills(props) {
    const {dark} = props;
    const myStyles = {
		color: dark ? '#ffffff' : '#000000'
	};

    const skills = [{
        "skill": "React",
        "percent": "90%"
    },{
        "skill": "Node",
        "percent": "90%"
    },{
        "skill": "Javascript",
        "percent": "80%"
    },{
        "skill": "C",
        "percent": "80%"
    },{
        "skill": "Mongo",
        "percent": "80%"
    },{
        "skill": "Python",
        "percent": "80%"
    },{
        "skill": "Java",
        "percent": "80%"
    },{
        "skill": "Unity",
        "percent": "80%"
    },{
        "skill": "Google Cloud Platform",
        "percent": "90%"
    },{
        "skill": "Amazon Web Services",
        "percent": "90%"
    },{
        "skill": "Jenkin",
        "percent": "80%"
    },{
        "skill": "Git",
        "percent": "80%"
    },{
        "skill": "Flutter",
        "percent": "60%"
    },{
        "skill": "Docker",
        "percent": "80%"
    },{
        "skill": "Selenium",
        "percent": "80%"
    },{
        "skill": "Augmented Reality",
        "percent": "80%"
    },{
        "skill": "Virtual Reality",
        "percent": "80%"
    },{
        "skill": "SQL",
        "percent": "80%"
    },{
        "skill": "Automation",
        "percent": "80%"
    },{
        "skill": "Socket Programming",
        "percent": "60%"
    },{
        "skill": "System Programming",
        "percent": "50%"
    },{
        "skill": "Linux",
        "percent": "80%"
    },{
        "skill": "Cyber Security",
        "percent": "60%"
    },{
        "skill": "Kubernetes",
        "percent": "50%"
    }]
	const bgtextStyle = {
		color: dark ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
		zIndex: 1,
		position: 'absolute',
		top: -20
	};



    const Level = (props) => {
        const {skill, percent} = props;
        return (
            <div class="col-md-6 animate-box">
                <div class="progress-wrap">
                    <h3 style={myStyles}>{skill}</h3>
                    <div class="progress">
                        <div class="progress-bar color-2" role="progressbar" aria-valuenow="90"
                        aria-valuemin="0" aria-valuemax="100" style={{width: percent}}>
                    
                        </div>
                    </div>
                </div>
            </div>
        )
    }

  return (
    <Reveal>
        <section class="ftco-section" id="skills-section">
        <div class="container">
            <div class="row justify-content-center pb-5">
                        <div class="col-md-10 heading-section text-center">
                                <div>
                                    <h1 class="big big-2" style={bgtextStyle}>My Skills</h1>
                                    <h2 class="mb-4" style={myStyles}>My Skills</h2>
                                </div>
                        </div>
            </div>
            <div class="row">
                {
                    skills.map((skill) => {
                        return (
                            <Level skill={skill.skill} percent={skill.percent}/>
                        )
                    })
                }
            </div>
        </div>
        </section>
    </Reveal>
  )
}
